.search_list > div {
  display: flex;
  align-items: center;
}
.search_list > div img {
  width: 100px;
  padding: 0 2em 2em 4em;
}
.details h4 {
  font-weight: 600;
}
.details h4 span.release_date{
  opacity: 0.8;
  font-weight: 400;
}
.actions {
  display: flex;
  margin-top: .5em;
}
.actions a,
.actions button {
  cursor: pointer;
}
