.bar-wrapper {
  background-color: #081c22;
  border-radius: 50%;
  padding: .3em;
}

.circle-track,
.circle-progress {
  fill: none;
}
.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.percent-text {
  font-weight: bold;
  fill: #fff;
}
.percent-symbol {
  vertical-align: super;
  font-size: 40%;
}
