a {
  color: black;
}
a:hover {
  color: black;
}

div.wrapper {
  width: 80%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
}
div.content_wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 1em;
}
div.content_wrapper img {
  display: block;
  width: 300px;
  height: 450px;
  outline: none;
}
section.details {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  box-sizing: border-box;
  padding-left: 40px;
}
div.title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.title h2 {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 2.2rem;
}
div.title h2 a {
  font-weight: 700;
  color: #000;
}
div.title h2 span.release_date{
  opacity: 0.8;
  font-weight: 400;
}

section.details div.title div.facts {
  display: flex;
  margin-top: 0.4em;
  margin-bottom: .5em;
}
section.details div.facts span+span {
  padding-left: 20px;
  position: relative;
  top: 0;
  left: 0;
}
section.details div.facts span+span:before {
  font-size: 1.1em;
  line-height: 1;
  content: '\2022';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 7px;
  display: inline-flex;
  align-content: center;
  align-items: center;
  z-index: -1;
}
section.details div.facts span.certification+span {
  padding-left:0;
}
section.details div.facts span.certification+span:before {
  content:'';
}
section.details div.facts span.certification {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  align-content: center;
  padding: 0.06em 4px 0.15em 4px!important;
  border: 1px solid rgba(0,0,0,0.6);
  color: rgba(0,0,0,0.6);
  line-height: 1;
  border-radius: 2px;
  margin-right: 7px;
}
section.details ul.ratings {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 20px;
}
section.details ul.ratings li {
  margin-right: 2em;
}
section.details ul.ratings li:last-child {
  margin-right: 0;
}
li.metascore {
  transition: transform .2s;
  transform: scale(1);
}
li.metascore:hover {
  cursor: pointer;
  transform: scale(1.1);
}
section.details ul.ratings li.text {
  font-weight: 700;
  white-space: pre-line;
}
li.imdb_ratings a {
  display: flex;
  align-items: center;
  flex-direction: column;
}
li.imdb_ratings .numbers {
  margin-top: .4em;
}
li.imdb_ratings .numbers span:first-child {
  margin-right: 1em;
}

section.details h3 {
  width: 100%;
  margin: 0 0 8px 0;
  font-weight: 600;
  font-size: 1.3em;
}
section.details p.plot {
  margin-top: 0;
  margin-bottom: 0;
}
h4 {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 4px;
}
.grid {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}
.grid > div {
  flex-basis: 100%;
  padding: 0 .7em;
}
.wide {
  text-align: center;
  width: 100%;
}
